import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";

import { useTranslation } from "react-i18next";

function ProjectCards(props) {
    const { t } = useTranslation();
    return (
        <Container fluid className="project-section">
            <Particle />
            <Container>
                <h1 className="project-heading">
                    {t('ExpeHeader')} <strong className="green">{t('Expe')} </strong>
                </h1>
                <p className="text">{t('ExpeSubtitle')}</p>

                <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
                </Row>
            </Container>
        </Container>
    );
}
export default ProjectCards;
