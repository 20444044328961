import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  SiLinux,
  SiWindows,
  SiAmazonaws,
  SiVim,
  SiDocker,
  SiGit,
  SiJupyter,
  SiGooglecloud,
  SiOpenai
} from "react-icons/si";


function Toolstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      {
        [
          <SiLinux/>,
          <SiWindows />,
          <SiGit/>,
          <SiVim/>,
          <SiDocker/>,
          <SiAmazonaws/>,
          <SiGooglecloud/>,
          <SiOpenai/>
        ].map(tool => 
            <Col xs={4} md={2} className="tech-icons">
              {tool}
          </Col>)
      }
    </Row>
  );
}

export default Toolstack;
