import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useThemeContext } from "../../hooks/themeHook";
import CertificationCard from "./CertificationCard";
import cloudPractioner from "../../assets/Certifications/cloud_practitioner.png";
import solutionArchitech from "../../assets/Certifications/solution_architecht.png";

function Certifications() {
  const { dark } = useThemeContext();

  return (
    // <Row style={{ justifyContent: "center", paddingBottom: "40px" }}>
    //   <h1 className="project-heading" style={{ paddingBottom: "20px" }}>
    //     <strong className="green">My</strong> Certifications
    //   </h1>
    //   <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
    //     <Col md={3} className="project-card">
    //       <CertificationCard
    //         imgPath={cloudPractioner}
    //         title="AWS Certified Cloud Practitioner"
    //         description=''
    //       // link="https://github.com/LaurentMazare/diffusers-rs"
    //       />
    //     </Col>
    //     <Col md={3} className="project-card">
    //       <CertificationCard
    //         imgPath={solutionArchitech}
    //         title="AWS Certified Solutions Architect - Associate"
    //         description=''
    //       // link="https://github.com/LaurentMazare/diffusers-rs"
    //       />
    //     </Col>
    //   </Row>
    // </Row>

    <Row style={{ justifyContent: "center", paddingBottom: "40px" }}>
      <h1 className="project-heading" style={{ paddingBottom: "20px" }}>
        <strong className="green">My</strong> Certifications
      </h1>
      <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
        {
          [
            {
              title: "AWS Certified Cloud Practitioner",
              image: cloudPractioner
            },
            {
              title: "AWS Certified Solutions Architect - Associate",
              image: solutionArchitech
            },
          ].map(cert =>
            <Col xs={12} md={3} className="project-card">
              {<CertificationCard
                imgPath={cert.image}
                title={cert.title}
              />}
            </Col>)
        }
      </Row>
    </Row>
  );
}

export default Certifications;
