import React from "react";
import { Container, Row, Col, Button, Modal, Form, Toast, ToastContainer } from "react-bootstrap";
import {
  AiFillGithub,
  AiFillMail,
} from "react-icons/ai";

import { FaLinkedinIn } from "react-icons/fa";
import { BsTelegram } from "react-icons/bs";

function Footer() {
  let date = new Date();
  let year = date.getFullYear();
  const [modalShow, setModalShow] = React.useState(false);
  const [toastShow, setToastShow] = React.useState(false);

  const handleClose = () => setModalShow(false);

  function MessageModal(props) {
    const [userEmail, setEmail] = React.useState('');
    const [userMessage, setMessage] = React.useState('');

    const sendMessage = () => {
      console.log("email", userEmail);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      urlencoded.append("data", JSON.stringify({
        "token": "XTTWLq",
        "event": "contact-us-my-website-user",
        "customer_properties": {
          "$email": `${userEmail}`
        },
        "properties": {
          "userMessage": userMessage
        }
      }));

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
      };

      fetch("https://a.klaviyo.com/api/track", requestOptions)
        .then(response => response.text())
        .then(result => {
          console.log(result);
          setModalShow(false);
          setToastShow(true);
          setTimeout(() => {
            setToastShow(false);
          }, 5000)
          setEmail('');
          setMessage('');
        })
        .catch(error => console.log('error', error));
    };

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        show={props.show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Send Your Message</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="Form.ControlInput1">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter your email id"
                autoFocus
                value={userEmail}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="Form.ControlTextarea1">
              <Form.Label>Your message</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                placeholder="Please summarize your purpose to connect..."
                value={userMessage}
                onChange={(e) => setMessage(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={sendMessage}>
            Drop Message
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  function ToastPopup(props) {
    return (
      <Row>
        <Col xs={6}>
          <ToastContainer
            className="p-2"
            position={"top-end"}
            style={{ zIndex: 9999999 }}
          >
            <Toast onClose={() => setToastShow(false)} {...props}>
              <Toast.Header>
                <img
                  src="holder.js/20x20?text=%20"
                  className="rounded me-2"
                  alt=""
                />
                <strong className="me-auto">Notification</strong>
                <small>Just now</small>
              </Toast.Header>
              <Toast.Body>Message Dropped!</Toast.Body>
            </Toast>
          </ToastContainer>
        </Col>
      </Row>

    );
  }

  return (
    <>
      <ToastPopup show={toastShow} />
      <Container fluid className="footer">
        <MessageModal
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
        <Row>
          <Col md="4" />
          {/* <Col md="4" className="footer-copyright">
          <h3>Copyright © {year} Yashu Gupta</h3>
        </Col>. */}

          <Col md="4" className="footer-copyright" style={{ marginBottom: 10 }}>
            <Button className="certificate-card-view" style={{ width: '200px' }} onClick={() => setModalShow(true)}>Wanna Connect!</Button>
          </Col>
          <Col md="4" className="footer-body">
            <ul>
              <li className="social-icons">
                <a className="footer-social-icons"
                  href="https://github.com/Hungry-Coder-byte"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a className="footer-social-icons"
                  href="https://www.linkedin.com/in/yashu-gupta-2560b716a"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a className="footer-social-icons"
                  href="#"
                  onClick={() => setModalShow(true)}
                  // target=""
                  rel="noopener noreferrer"
                >
                  <AiFillMail />
                </a>
              </li>
              {/* <li className="social-icons">
              <a className="footer-social-icons"
                href="https://t.me/E_ll3"
                target="_blank"
                rel="noopener noreferrer"
              >
                <BsTelegram />
              </a>
            </li> */}
            </ul>
          </Col>
          {/* <Col md="4" className="footer-body" /> */}
        </Row>
      </Container>
    </>
  );
}

export default Footer;